// 产品中心
// mxy
<template>
  <div class="submenu flex-column align-center">
    <div class="tabes flex-column align-center">
      <div class="header"></div>
      <a-tabs
        tab-position="top"
        default-active-key="1"
        v-model:activeKey="activeKey"
        @change="changeTab"
      >
        <a-tab-pane v-if="menu.length !== 0" tab="全部" key="1">
          <a-spin :spinning="spinning">
            <a-empty v-if="list.length == 0" description="暂无数据" />
            <a-row :gutter="16">
              <a-col :span="6" v-for="item in list" :key="item">
                <a-card
                  style="border-radius: 8px"
                  hoverable
                  :bordered="false"
                  @click="application(item)"
                >
                  <!-- <a-tag class="tag" v-if="item.isOwn" color="red"
                    >已添加</a-tag
                  > -->
                  <div class="right_top">
                    <!-- <img
                      v-if="item.isOwn"
                      src="@/assets/iocn_wujiaoxin.png"
                      alt=""
                    /> -->
                    <AppQRCode v-if="item.isOwn == true && item.overdue == false && item.miniPro"
                      :pictureUrl="item.miniPro" />
                  </div>
                  <!-- <div class="versionCode flex">
                    <div v-if="item.versionCodeOn !== item.versionCode">
                      {{ item.versionCodeOn }}
                    </div>
                    <ArrowRightOutlined
                      v-if="
                        item.versionCode &&
                        item.versionCodeOn !== null &&
                        item.versionCodeOn !== '' &&
                        item.versionCodeOn !== item.versionCode
                      "
                      style="margin-top: 5px; padding: 0 6px; font-size: 12px"
                    />
                    <div>{{ item.versionCode }}</div>
                  </div> -->
                  <div class="flex title">
                    <img :src="item.applicationIcon" alt="" class="logo" />
                    <div class="app_title_box flex-column">
                      <div class="name" :title="item.applicationName">
                        {{ item.applicationName }}
                      </div>
                      <div v-show="item.tip" :title="item.tip" class="app_tip">{{item.tip}}</div>
                    </div>
                    
                  </div>
                  <div class="introduce">
                    <span>{{ item.briefDetails }}</span>
                  </div>
                  <div class="flex justify-between">
                    <a-button
                      :class="disabled === true ? '' : 'appBuyWayBtn'"
                      :disabled="disabled"
                      v-if="item.appBuyWay == 0"
                    >
                      添加
                    </a-button>
                    <a-button
                      :disabled="disabled"
                      :class="disabled === true ? '' : 'appBuyWayBtn'"
                      v-if="
                        item.appBuyWay == 1 &&
                        item.isTrial == true &&
                        item.overdue == false
                      "
                    >
                      试用
                    </a-button>
                    <a-button
                      :disabled="disabled"
                      :class="disabled === true ? '' : 'appBuyWayBtn'"
                      v-if="
                        item.appBuyWay == 1 &&
                        (item.isTrial == false || item.overdue == true)
                      "
                    >
                      购买
                    </a-button>
                    <div :class="item.appBuyWay == 0 ? 'free' : 'price'">
                      {{
                        item.appBuyWay == 0
                          ? "免费"
                          : "¥" + item.appPrice + "/" + item.appPriceUnitName
                      }}
                    </div>
                  </div>
                </a-card>
                <a-button
                  :disabled="disabled"
                  class="openBtn"
                  v-if="item.isOwn == true && item.overdue == false && item.onlyMobile === false"
                  @click="isOwn(item)"
                >
                  打开
                </a-button>
                <a-button
                  :disabled="disabled"
                  class="openBtn"
                  v-if="item.isOwn == true && item.overdue == false && item.onlyMobile === true"
                  @click="clickScanBtn(item)"
                >
                  扫码
                </a-button>
                <!-- 待确定 -->
                <!-- <a-button
                  class="openBtn"
                  v-if="
                    item.needUpdate == true &&
                    item.versionUpdating == false &&
                    item.overdue == false &&
                    item.versionCodeOn !== item.versionCode
                  "
                  @click="update(item)"
                >
                  更新
                </a-button> -->
                <!-- <a-button
                  class="openBtn"
                  v-if="item.versionUpdating === true"
                  :disabled="disabled"
                >
                  更新中
                </a-button> -->
                <!-- <a-button
                  class="openBtn"
                  v-if="item.appBuyWay == 1"
                  @click="isOwn(item)"
                >
                  购买
                </a-button> -->
              </a-col>
            </a-row>
          </a-spin>
        </a-tab-pane>
        <a-tab-pane v-for="i in menu" :key="i.id" :tab="i.industryName">
          <a-spin :spinning="spinning">
            <a-empty description="暂无数据" v-if="list.length == 0" />
            <a-row :gutter="16">
              <a-col :span="6" v-for="item in list" :key="item">
                <a-card
                  style="border-radius: 8px"
                  hoverable
                  :bordered="false"
                  @click="application(item)"
                >
                  <!-- <a-tag class="tag" v-if="item.isOwn" color="red"
                    >已添加</a-tag
                  > -->
                  <div class="right_top">
                    <!-- <img
                      v-if="item.isOwn"
                      src="@/assets/iocn_wujiaoxin.png"
                      alt=""
                    /> -->
                    <AppQRCode v-if="item.isOwn == true && item.overdue == false && item.miniPro"
                      :pictureUrl="item.miniPro" />
                  </div>
                  
                  <!-- <div class="versionCode flex">
                    <div v-if="item.versionCodeOn !== item.versionCode">
                      {{ item.versionCodeOn }}
                    </div>
                    <ArrowRightOutlined
                      v-if="
                        item.versionCode &&
                        item.versionCodeOn !== null &&
                        item.versionCodeOn !== '' &&
                        item.versionCodeOn !== item.versionCode
                      "
                      style="margin-top: 5px; padding: 0 6px; font-size: 12px"
                    />
                    <div>{{ item.versionCode }}</div>
                  </div> -->
                  <div class="flex title">
                    <img :src="item.applicationIcon" alt="" class="logo" />
                    <!-- <div class="name" :title="item.applicationName">
                      {{ item.applicationName }}
                    </div> -->
                    <div class="app_title_box flex-column">
                      <div class="name" :title="item.applicationName">
                        {{ item.applicationName }}
                      </div>
                      <div v-show="item.tip" :title="item.tip" class="app_tip">{{item.tip}}</div>
                    </div>
                  </div>
                  <div class="introduce">
                    <span>{{ item.briefDetails }}</span>
                  </div>
                  <div class="flex justify-between">
                    <a-button
                      :class="disabled === true ? '' : 'appBuyWayBtn'"
                      :disabled="disabled"
                      v-if="item.appBuyWay == 0"
                    >
                      添加
                    </a-button>
                    <a-button
                      :disabled="disabled"
                      :class="disabled === true ? '' : 'appBuyWayBtn'"
                      v-if="
                        item.appBuyWay == 1 &&
                        item.isTrial == true &&
                        item.overdue == false
                      "
                    >
                      试用
                    </a-button>
                    <a-button
                      :disabled="disabled"
                      :class="disabled === true ? '' : 'appBuyWayBtn'"
                      v-if="
                        item.appBuyWay == 1 &&
                        (item.isTrial == false || item.overdue == true)
                      "
                    >
                      购买
                    </a-button>
                    <div :class="item.appBuyWay == 0 ? 'free' : 'price'">
                      {{
                        item.appBuyWay == 0
                          ? "免费"
                          : "¥" + item.appPrice + "/" + item.appPriceUnitName
                      }}
                    </div>
                  </div>
                </a-card>
                <!-- <a-card
                  style="border-radius: 8px"
                  hoverable
                  @click="application(item)"
                  :bordered="false"
                > -->
                <!-- <a-tag class="tag" v-if="item.isOwn" color="red"
                    >已添加</a-tag
                  > -->
                <!-- <img
                    class="wu-jiao-xing"
                    v-if="item.isOwn"
                    src="@/assets/iocn_wujiaoxin.png"
                    alt=""
                  />
                  <div class="versionCode flex">
                    <div>{{ item.versionCodeOn }}</div>
                    <ArrowRightOutlined
                      v-if="item.versionCode && item.versionCodeOn !== null"
                      style="margin-top: 5px; padding: 0 6px; font-size: 12px"
                    />
                  </div>
                  <div class="flex title">
                    <img :src="item.applicationIcon" alt="" class="logo" />
                    <div class="name" :title="item.applicationName">
                      {{ item.applicationName }}
                    </div>
                  </div>
                  <div class="introduce">
                    <span>{{ item.briefDetails }}</span>
                  </div>
                  <div class="flex justify-between">
                    <a-button class="appBuyWayBtn" v-if="item.appBuyWay == 0">
                      试用
                    </a-button>
                    <a-button
                      class="appBuyWayBtn"
                      v-if="
                        item.appBuyWay == 1 &&
                        item.isTrial == true &&
                        item.overdue == false
                      "
                    >
                      试用
                    </a-button>
                    <a-button
                      class="appBuyWayBtn"
                      v-if="
                        item.appBuyWay == 1 &&
                        (item.isTrial == false || item.overdue == true)
                      "
                    >
                      购买
                    </a-button>
                    <div :class="item.appBuyWay == 0 ? 'free' : 'price'">
                      {{
                        item.appBuyWay == 0
                          ? "免费"
                          : "¥" + item.appPrice + "/" + item.appPriceUnitName
                      }}
                    </div>
                  </div>
                </a-card> -->
                <a-button
                  :disabled="disabled"
                  class="openBtn"
                  v-if="item.isOwn == true && item.overdue == false && item.onlyMobile === false"
                  @click="isOwn(item)"
                >
                  打开
                </a-button>
                <a-button
                  :disabled="disabled"
                  class="openBtn"
                  v-if="item.isOwn == true && item.overdue == false && item.onlyMobile === true"
                  @click="clickScanBtn(item)"
                >
                  扫码
                </a-button>
                <!-- <a-button
                  class="openBtn"
                  v-if="
                    item.needUpdate == true &&
                    item.versionUpdating == false &&
                    item.overdue == false &&
                    item.versionCodeOn !== item.versionCode
                  "
                  @click="update(item)"
                >
                  更新
                </a-button>
                <a-button
                  class="openBtn"
                  v-if="item.versionUpdating === true"
                  :disabled="disabled"
                >
                  更新中
                </a-button> -->
              </a-col>
            </a-row>
          </a-spin>
        </a-tab-pane>
      </a-tabs>
    </div>

    <a-modal
      title="到期提醒"
      :visible="overdueVisible"
      :confirm-loading="confirmLoading"
      cancelText="取消"
      :maskClosable="false"
      okText="确认"
      @ok="overdueCancel"
      @cancel="overdueCancel"
    >
      <div>
        <div class="overdue-title">{{ titleList.applicationName }}</div>
        <div v-if="titleList.appBuyWay == 0" style="padding: 5px 0">
          试用{{ titleList.trialValidity }}天,已于{{ titleList.startTime }}
          <span>至</span> {{ titleListTime }}
        </div>
        <div>
          请点击
          <span class="buy font-size" @click="buy()">联系购买</span>
          或拨打电话 <span class="font-size">15862885968</span>
        </div>
        <!-- <span>{{ titleList.expireTime }}</span>
        <span>—</span>
        <span>{{ titleList.expireTime }}</span>
        <div>试用{{titleList.trialValidity}}天,</div> -->
      </div>
    </a-modal>
    <a-modal
      title="购买信息"
      :visible="visible"
      cancelText="取消"
      :maskClosable="false"
      okText="确认"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <a-spin :spinning="spinning">
        <a-form
          :model="register"
          ref="formRef"
          :rules="rules"
          autocomplete="off"
          :labelCol="{ span: 6 }"
          :wrapperCol="{ span: 18 }"
        >
          <a-form-item required name="name" label="姓名">
            <a-input
              style="width: 80%"
              size="large"
              :maxLength="30"
              type="text"
              v-model:value="register.name"
              placeholder="请输入"
            >
            </a-input>
          </a-form-item>
          <a-form-item required name="phoneNumber" label="手机号">
            <a-input
              style="width: 80%"
              size="large"
              :maxLength="11"
              type="text"
              v-model:value="register.phoneNumber"
              placeholder="请输入"
            >
            </a-input>
          </a-form-item>
          <a-form-item required name="companyName" label="企业名称">
            <a-input
              style="width: 80%"
              size="large"
              :maxLength="30"
              type="text"
              v-model:value="register.companyName"
              placeholder="请输入"
            >
            </a-input>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>

    <!-- 扫码跳转小程序 -->
    <a-modal
      title="您可以通过微信扫码访问"
      :visible="showMobileModal"
      :destroyOnClose="true"
      :footer="null"
      @cancel="closeMobileModal"
    >
      <!-- <div>你可以通过微信扫码访问</div> -->
      <div>
        <div id="ApplicationQRCode" class="QRcode_box">
          <!-- <ApplicationQRCode :pictureUrl="mobilePicUrl" /> -->
          <img :src="mobilePicUrl" style="width: 150px;height: 150px;" alt="小程序">
        </div>
        <!-- <div class="mobileApplicationName">{{mobileApplicationName}}</div> -->
      </div>
      
    </a-modal>
    <!-- <a-button class="btn" :disabled="disabled" @click="createApp"
      >创建应用</a-button
    > -->
    <!-- <a-modal
      title="创建应用"
      :visible="visible"
      :confirm-loading="confirmLoading"
      ok-text="创建"
      cancel-text="取消"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="应用名称">
          <a-input
            size="large"
            :maxLength="30"
            type="text"
            v-model:value="register.name"
            placeholder="请输入应用名称"
          ></a-input>
        </a-form-item>
        <a-form-item label="应用图标">
          <a-input
            size="large"
            :maxLength="30"
            type="text"
            v-model:value="register.name"
            placeholder="请输入应用名称"
          ></a-input>
        </a-form-item>
        <a-form-item label="应用颜色">
          <a-input
            size="large"
            :maxLength="30"
            type="text"
            v-model:value="register.name"
            placeholder="请输入应用名称"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal> -->
    <!-- <div class="view">
      <router-view />
    </div> -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
// import { ArrowRightOutlined } from '@ant-design/icons-vue'
// import ApplicationQRCode from '@/components/ApplicationQRCode'
import api from '@/api/API'
import { getProfileDetail } from '@/api/IdentityAPI'
import AppQRCode from '@/components/AppQRCode'
import { VersionDue, NotIbpsAppliationUrl } from '@/assets/common.js'
import dayjs from 'dayjs'
import { getEnvVars } from '@/api/config'
const { imgUrl } = getEnvVars()
// import QRCode from 'qrcodejs2'

export default defineComponent({
  components: {
    // ArrowRightOutlined,
    // ApplicationQRCode,
    AppQRCode
  },
  data () {
    return {
      imgPath: imgUrl,
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          },
          {
            len: 11,
            message: '请输入正确的11位手机号码',
            trigger: 'blur',
          },
        ],
        companyName: [
          {
            required: true,
            message: '请输入公司名称',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
      details: true,
      disabled: false,
      menu: [],
      activeKey: '1',
      visible: false,
      overdueVisible: false,
      confirmLoading: false,
      register: {
        name: '',
      },
      path: '',
      list: [],
      id: {},
      spinning: false,
      titleList: {},
      titleListTime: '',
      showMobileModal: false,
      // mobileApplication: {},
      mobilePicUrl: '',
      mobileApplicationName: '',
      qrImg_url: '' // 小程序
    }
  },
  watch: {
  },
  created () {
    console.log('imgPath', this.imgPath)
    const tenantList = JSON.parse(localStorage.getItem('tenantList'))
    // if (tenantList.isFree === true) {
    //   this.disabled = true
    // }
  },
  mounted () {
    this.getIndustryList()
    this.getProduct()
  },
  methods: {
    update (item) {
      this.spinning = true
      const obj = {
        id: item.id,
      }
      api
        .post('/api/app/sys-application-on/sys-application-on-version-upgrade', obj)
        .then(({ data }) => {
          if (data === true) {
            this.$message.success('正在更新')
          } else {
            this.$message.error('更新失败')
          }
          this.spinning = false
          this.getProduct()
        })
        .catch(err => {
          // this.$message.error('获取失败' + err)
          this.spinning = false
          console.log(err)
        })
    },
    // 切换行业table
    changeTab (activeKey) {
      this.spinning = true
      if (activeKey === '1') {
        this.getProduct()
        return
      }
      this.id = {
        id: activeKey,
      }
      // /api/app/sys-application-on/center-by-industry
      api
        .get('/api/app/sys-application-on/center-by-industry', {
          params: {
            IndustryId: this.id.id,
            FromMobile: true, // 同时展示移动端的应用
          },
        })
        .then(({ data }) => {
          // this.list = data
          // 0 小程序 1 Web网页 2 移动端App  3 移动端网页
          const res = data
          res.forEach(i => {
            const arr = i.urls.map(k => {
              if (k.applicationType === 0) {
                i.miniPro = k.pictureUrl
              }
              return k.applicationType
            })
            let str = '端口 : '
            if (!i.isNotIbps) { // 低代码应用，urls数组是空的，会直接通过点击打开按钮跳转web网页
              str += 'PC端'
              i.onlyMobile = false
            } else {
              if (arr.includes(1)) { // 非低代码应用，urls数组肯定有内容，应用类型是1就是web网页，0就是微信小程序
                str += 'PC端'
                if (arr.includes(0)) {
                  str += ' / 移动端'
                }
                i.onlyMobile = false
              } else if (arr.includes(0)) {
                str += '移动端'
                i.onlyMobile = true // 该应用纯属移动端
              }
            }
            i.tip = str
          });
          this.list = res
          console.log('整合后的list', res);
          this.spinning = false
        })
        .catch(err => {
          // this.$message.error('获取失败' + err)
          this.spinning = false
          console.log(err)
        })
    },
    // createApp () {
    //   this.visible = true
    // },
    // handleOk () {
    //   this.confirmLoading = true
    // },
    // handleCancel () {
    //   this.visible = false
    // },
    // 获取行业列表
    getIndustryList () {
      api
        .get('/api/app/sys-industry/sys-industry-list')
        .then(({ data }) => {
          this.menu = data
        })
        .catch(err => {
          console.log(err)
          // this.$message.error('获取失败' + err)
        })
    },
    // 全部产品
    getProduct () {
      this.spinning = true
      api
        .get('/api/app/sys-application-on/center-list', {
          params: {
            fromMobile: true, // 同时展示移动端的应用
          },
        })
        .then(({ data }) => {
          // 0 小程序 1 Web网页 2 移动端App  3 移动端网页
          const res = data
          res.forEach(i => {
            const arr = i.urls.map(k => {
              if (k.applicationType === 0) {
                i.miniPro = k.pictureUrl
              }
              return k.applicationType
            })
            let str = '端口 : '
            if (!i.isNotIbps) { // 低代码应用，urls数组是空的，会直接通过点击打开按钮跳转web网页
              str += 'PC端'
              i.onlyMobile = false
            } else {
              if (arr.includes(1)) { // 非低代码应用，urls数组肯定有内容，应用类型是1就是web网页，0就是微信小程序
                str += 'PC端'
                if (arr.includes(0)) {
                  str += ' / 移动端'
                }
                i.onlyMobile = false
              } else if (arr.includes(0)) {
                str += '移动端'
                i.onlyMobile = true // 该应用纯属移动端
              }
            }
            i.tip = str
          });
          this.list = res
          console.log('整合后的list', res);
          this.spinning = false
        })
        .catch(err => {
          // this.$message.error('获取失败' + err)
          this.spinning = false
          console.log(err)
        })
    },
    
    clickScanBtn(item) { // 扫码按钮点击事件
      this.mobilePicUrl = item.miniPro
      this.showMobileModal = true
    },
    // 获取是否拥有模板
    isOwn (item) {
      console.log('item',item)
      if (VersionDue()) {
        this.$message.warning('当前企业版本已过期，请联系客服人员')
        return
      }
      this.spinning = true
      if (item.overdue === true) {
        this.overdueVisible = true
        this.spinning = false
        this.titleList = item
        this.titleListTime = dayjs(item.expireTime).format('YYYY-MM-DD HH:mm:ss')
        getProfileDetail().then(data => {
          if (data.status === 1) {
            this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
            window.location.href = '/account/login'
            window.localStorage.clear()
          }
          this.register.name = data.name
          this.register.phoneNumber = data.phoneNumber
        })
        const tenantList = JSON.parse(localStorage.getItem('tenantList'))
        this.register.companyName = tenantList.enterpriseName
        return
      }
      if (item.isOwn === true) {
        // 若为非低代码平台应用 则获取其跳转web页的url
        if (item.isNotIbps) {

          //  若为非低代码平台应用，item.urls肯定不为空
          // let url = item.urls.find(e => e.applicationType === 1).url
          let target = item.urls.find(e => e.applicationType === 1)

          //  若应用类型不为1，就是小程序 则弹框显示小程序码
          // 0 小程序 1 Web网页 2 移动端App 3 移动端网页
          // if (target === undefined) {
          //   const { imgUrl } = getEnvVars()
          //   this.spinning = false
          //   // this.mobileApplication = item
          //   // this.mobileApplicationName = item.applicationName
            
          //   if (item.applicationName === '威市场') {
          //     this.qrImg_url = imgUrl + '39fdd8e1-f927-3be1-af35-f929a70cbf6e/logo_wsc.jpg'
          //   } else if (item.applicationName === '威物联') {
          //     this.qrImg_url = imgUrl + '39fdd8e1-f927-3be1-af35-f929a70cbf6e/logo_wwl.jpg'
          //   } else {
          //     this.qrImg_url = imgUrl + '39fdd8e1-f927-3be1-af35-f929a70cbf6e/logo_wzz.jpg'
          //   }
          //   this.showMobileModal = true
  
          //   return
          // }
          //  若应用类型为1，就是web网页，PC端可以跳转
          let url = target.url
          url = NotIbpsAppliationUrl(url)
          window.open(url, '_blank')
          this.spinning = false
          return
        }
        api
          .get('/api/app/sys-application-on/application-can-be-use', {
            params: {
              id: item.id,
            },
          })
          .then(({ data }) => {
            api
              .get('/api/app/sys-application-on/application-on-id', {
                params: {
                  id: item.id,
                },
              })
              .then(({ data }) => {
              //原本传参方式
              //  const routeData = this.$router.resolve({ path: '/ApplicationDetails', query: { id: data.id } })
                  const routeData = this.$router.resolve({ path: `/ApplicationDetails/${data.id}` })
                window.open(routeData.href, '_blank')
                this.spinning = false
                // window.location.href = '/ApplicationDetails?id=' + data.id + ''
              })
              .catch(err => {
                console.log(err)
                this.spinning = false
                // this.$message.error('获取失败' + err)
              })
            // }
            // window.location.href = '/ApplicationDetails?id=' + data.id + ''
          })
          .catch(err => {
            console.log(err)
            this.spinning = false
            // console.log(data)

            // this.$message.error('获取失败' + err)
          })
      }
    },
    closeMobileModal() {
      this.showMobileModal = false;
      this.mobilePicUrl = ''
    },
    // 购买产品
    pay (item) {
      this.overdueVisible = true
      this.spinning = false
      this.titleList = item
      this.titleListTime = dayjs(item.expireTime).format('YYYY-MM-DD HH:mm:ss')
      getProfileDetail().then(data => {
        if (data.status === 1) {
          this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
          window.location.href = '/account/login'
          window.localStorage.clear()
        }
        this.register.name = data.name
        this.register.phoneNumber = data.phoneNumber
      })
      const tenantList = JSON.parse(localStorage.getItem('tenantList'))
      this.register.companyName = tenantList.enterpriseName
    },
    buy () {
      this.visible = true
    },
    // 确定
    handleOk () {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        this.spinning = true
        const obj = {
          name: this.register.name,
          phone: this.register.phoneNumber,
          companyName: this.register.companyName,
          versionName: this.titleList.applicationName,
        }
        api
          .post('/api/app/message/purchase-version', obj)
          .then(({ data }) => {
            this.confirmLoading = false
            this.spinning = false
            this.$message.success('您已成功提交购买信息！')
            this.handleCancel()
            this.overdueCancel()
            this.overdueVisible = false
          })
          .catch(err => {
            this.confirmLoading = false
            this.spinning = false
            // this.$message.error('创建失败' + err)
            console.log(err)
          })
      })
    },
    // 取消
    handleCancel () {
      this.visible = false
      this.$refs.formRef.resetFields()
    },
    // 到期提醒框取消
    overdueCancel () {
      this.overdueVisible = false
    },
    // 购买app
    payApp (item) {
      this.pay(item)
    },
    // 跳转详情页面
    application (item) {
      this.id = item.id
      this.$router.push({
        path: '/ProductCentre/Detail',
        query: {
          id: this.id,
          tip: item.tip,
          miniPro: item.miniPro ? item.miniPro : ''
        },
      })
    },
  },
})

</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.submenu {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  .tabes {
    width: 100%;
    height: 100%;
    .header {
      width: 100%;
      height: 64px;
      background-color: #fff;
      position: absolute;
    }
    .right_top {
      position: absolute;
      right: 26px;
      top: 30px;
    }
    .wu-jiao-xing {
      position: absolute;
      right: 26px;
    }
    .versionCode {
      position: absolute;
      left: 110px;
      top: 74px;
    }
    .title {
      // height: 68px;
      // line-height: 68px;
    }
    .logo {
      width: 68px;
      height: 68px;
      margin-right: 20px;
    }
    .name {
      opacity: 1;
      font-size: 16px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 600;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .ant-empty {
      margin: 95px 8px;
    }
    .introduce {
      margin: 10px 0 20px 0;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ant-btn {
      &:hover {
        border-color: #fff;
        background-color: #134ccf;
        color: #fff;
      }
    }
    .appBuyWayBtn {
      border-color: #134ccf;
      color: #134ccf;
    }
    .free {
      position: relative;
      top: 4px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: #333;
      font-size: 12px;
      white-space: nowrap;
    }
    .price {
      position: relative;
      left: 0;
      top: 4;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: red;
      font-size: 12px;
      white-space: nowrap;
    }
    .openBtn {
      position: absolute;
      bottom: 24px;
      left: 32px;
    }
    .overdue-title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .buy {
      cursor: pointer;
    }
  }
}

.ant-col {
  margin: 10px 0;
}
::v-deep(.ant-tabs) {
  width: 82%;
}
::v-deep(.ant-tabs-bar) {
  width: 100%;
  border: none;
  padding: 10px 0;
  background-color: #fff;
}

.QRcode_box {
  width: 180px;
  margin: 0 auto;
}
.mobileApplicationName {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  color: #2B60D9;
}

::v-deep(.ant-card-body) {
  position: relative;
}

.app_title_box {
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 0;
  .app_tip {
    color: #999999;
    font-size: 12px;
  }
}
</style>
